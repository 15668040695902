import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import MyDropzone from "../../Components/MyDropZone";
import TextEditor from "../../Components/TextEditor";
import ValidateSubmit from "wearslot-dev-utils";
import { errorResponse, responseMessage } from "../../libs/app";
import VariationField from "./VariationField";
import $ from "jquery";
import Variant from "./Variant";
import VariationOption from "./VariationOption";
import { FiTrash2 } from "react-icons/fi";
import CreatableInputOnly from "../../Components/CreatableInputOnly";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import Placeholder from "../../Components/Placeholder/Placeholder";
import DeleteModal from "../../Components/DeleteModal";
import { AppContext } from "../../Contexts/AppContext";
import IconWithTooltip from "../../Components/IconWithTooltip";
import { checkIfChannelConnected } from "../Settings/SalesChannels";
import CreatableSingle from "../../Components/CreateAbleSelect";

import UploadWidget from "../../Components/UploadWidget";

import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";

const ProductUpload = (props) => {
  if (props.mode === "new")
    document.title = "Create Product | Taojaa - Store Manager App";
  if (props.mode === "edit")
    document.title = "Edit Product | Taojaa - Store Manager App";
  const { availability_types, collectionSlugs, setCollectionSlugs } =
    useContext(AppContext);

  const {
    store,
    currency,
    subscription,
    recordCounts,
    handleUpgradeModalShow,
  } = useContext(AuthContext);
  const { product_id } = useParams();
  const [product, setProduct] = useState({
    type: "physical",
  });
  const [images, setProductImages] = useState({
    main: "",
    gallery: [],
  });
  const [description, setDescription] = useState("");
  const [seo, setSeo] = useState({});
  const [settings, setSettings] = useState({ publish_schedule: "" });
  const [inventory, setInventory] = useState({});
  const [productVariationOptions, setProductVariationOptions] = useState([]);
  const [googleProductCategories, setGoogleProductCategories] = useState([]);
  const [variationTypes, setVariationTypes] = useState([]);
  const [variants, setVariants] = useState([]);
  const [variations, setVariations] = useState([]);
  const [variation_count, setVariationCount] = useState(0);

  const [formatted, setFormatted] = useState([]);
  const [publish, setPublish] = useState(1);
  const [featured, setFeatured] = useState(0);
  const [publishToChannels, setPublishToChannels] = useState(1);
  const [variantSelectedForRemoval, setVariantSelectedForRemoval] =
    useState(null);
  const [hashtags, setHashTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [connectedChannels, setConnectedChannels] = useState([]);
  const [selectGoogleCategory, setSelectedGoogleCategory] = useState(null);

  var initial = moment();
  const [initialDate, setInitialDate] = useState(initial.format("YYYY-MM-DD"));

  const { videoUrl, setVideoUrl, setVideo } =
    React.useContext(AppContext);
  const [collectionOption, setCollectionOption] = useState([]);
  const [seletedCollectionOptions, setSelectedCollectionOptions] = useState([])

  const navigate = useNavigate();

  useEffect(() => {

    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getCollections();
    getProductDetails();
    variantOptions();
    getVendorConnectedChannels();
    getGoogleProductCategories();

    if (props.mode === "new") {
      checkImagesInLocalStorage();
    }
  }, [store, subscription]);

  useEffect(() => {
    formatVariations();
  }, [variations, variantSelectedForRemoval]);

  async function getVendorConnectedChannels() {
    try {
      const response = await axios.get("/settings/connected-sales-channels");
      if (response?.data?.success) {
        setConnectedChannels(response?.data?.data);
      }
    } catch (error) {
      if (error?.response) errorResponse(error?.response);
      else responseMessage("Something went wrong.", "error");
    }
  }

  const checkChannelStatus = (slug) => {
    return checkIfChannelConnected(slug, connectedChannels) ? true : false;
  };

  const variantOptions = async () => {
    try {
      const response = await axios.get("/get-variation-types");

      if (response?.data?.success) {
        var variants = response?.data?.data;
        if (variants) {
          setVariationTypes(
            variants.map((variant) =>
              Object.assign({ value: variant?.id, label: variant?.name })
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createOption = ({ name, slug }) => {
    return {
      value: slug,
      label: name,
    };
  };
  const getCollections = () => {
    axios
      .get(`/collections`)
      .then((response) => {
        var collection = response?.data;
        const options = collection?.data.map(createOption);
        setCollectionOption(options);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGoogleProductCategories = () => {
    axios
      .get(`/google-product-categories`)
      .then((response) => {
        setGoogleProductCategories(response.data.data);
      })
      .catch((error) => { });
  };

  const getProductDetails = () => {
    if (product_id && props.mode === "edit") {


      axios
        .get(`/product/details/${product_id}`)
        .then((response) => {
          var data = response.data.product;

          const url = data.images.video;
          var fileName = null;
          if (url) {
            fileName = url.split("/").pop()
              .split(".")
              .slice(0, -1)
              .join(".");
          }
          const optionsSelected = data.collections.map(createOption);
          setSelectedCollectionOptions(optionsSelected);
          const newCategories = data.collections.map((category) => category.slug);
          setCollectionSlugs(newCategories)
          setProduct(data);
          setSettings(data.settings);
          setSeo(data.seo);
          setInventory(data.inventory);
          setProductImages(data.images);
          setVideoUrl(url);
          setVideo(fileName);
          setDescription(data.description);
          setPublish(data.published.status ? 1 : 0);
          setFeatured(data.is_featured ? 1 : 0);
          setPublishToChannels(data.published.to_sales_channels ? 1 : 0);

          if (data.hash_tags !== null && data.hash_tags !== "") {
            var hash_tags = data.hash_tags.split(",");
            var tags = hashtags;
            hash_tags.forEach((tag, index) => {
              tags[index] = { label: tag, value: tag };
            });
            setHTags(tags);
          }

          if (data.variations) {
            data.variations.map((variation, index) => {
              let variant = {
                value: variation.type.id,
                label: variation.type.name,
              };
              if (!exist(variants, variant)) {
                var variantslist = variants;
                variantslist[index] = variant;
                setVariants(variantslist);

                var valueslist = variations;
                valueslist[index] = variation.values;
                setVariations(valueslist);
              }
            });

            setVariationCount(data.variations.length);
            setProductVariationOptions(data.variation_options);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error?.response) return errorResponse(error?.response);

          return responseMessage("Something went wrong.", "error");
        });
    } else {
      setLoading(false);
    }
  };

  const getProductVaritions = async () => {
    const response = await axios.get(`/product/variations/${product_id}`);
    if (response.data.success) {
      setProductVariationOptions(response.data.variations);
    }
  };

  const setHTags = (tags) => {
    setHashTags(tags);
  };

  const setPublishDate = (date) => {
    var settingData = { ...settings, publish_schedule: date };
    setSettings(settingData);
    setInitialDate(date);
  };

  const checkImagesInLocalStorage = () => {
    var localImages = localStorage.getItem("images");
    if (localImages !== null && localImages !== "") {
      localImages = JSON.parse(localImages);
      let $images = {
        main: localImages[0],
        gallery: localImages.splice(1),
      };

      setProductImages($images);
    }
  };

  const removeMainImage = () => {
    var exitingGallery = [...images.gallery];
    const newImages = {
      ...images,
      main: exitingGallery.length ? exitingGallery[0] : "",
      gallery: exitingGallery.length ? exitingGallery.splice(1) : [],
    };

    const $data = {
      ...newImages,
      product_id: product.id,
      gallery: newImages.gallery.join(","),
      image_location: images.main,
    };

    if (props.mode === "edit") {
      axios
        .post(`/update-product-images`, $data)
        .then((response) => {
          if (response.data.success) {
            responseMessage(response.data.message, "success");
            setProductImages(response.data.images);
          }
        })
        .catch((error) => {
          if (error.response) {
            return errorResponse(error.response);
          }
          return responseMessage(
            "Something went wrong, please try again",
            "error"
          );
        });
    } else {
      var lsImages = JSON.parse(localStorage.getItem("images"));
      lsImages.splice(0, 1);

      axios
        .post(`/remove-image`, $data)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem("images", JSON.stringify(lsImages));
            responseMessage(response.data.message, "success");
            setProductImages(newImages);
          }
        })
        .catch((error) => {
          if (error?.response) return errorResponse(error?.response);

          return responseMessage(
            "Something went wrong, please try again",
            "error"
          );
        });
    }
  };

  const removeGalleryImage = (index) => {
    var exitingGallery = [...images.gallery];
    const rmImages = exitingGallery.splice(index, 1);

    const newImages = {
      ...images,
      gallery: exitingGallery,
    };

    var $data = {
      ...newImages,
      image_location: rmImages.join(""),
    };

    if (props.mode === "edit") {
      $data.product_id = product.id;

      axios
        .post(`/update-product-images`, $data)
        .then((response) => {
          if (response.data.success) {
            responseMessage(response.data.message, "success");
            setProductImages(response.data.images);
          }
        })
        .catch((error) => {
          if (error.response) return errorResponse(error.response);

          return responseMessage(
            "Something went wrong, please try again",
            "error"
          );
        });
    } else {
      var lsImages = JSON.parse(localStorage.getItem("images"));
      lsImages.splice(index + 1, 1);

      axios
        .post(`/remove-image`, $data)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem("images", JSON.stringify(lsImages));
            responseMessage(response.data.message, "success");
          }
        })
        .catch((error) => {
          if (error.response) return errorResponse(error.response);

          return responseMessage(
            "Something went wrong, please try again",
            "error"
          );
        });

      setProductImages(newImages);
    }
  };

  const extractHashTags = (index, value) => {
    setHashTags([...value]);
  };

  const addVariation = () => {
    var count = variation_count + 1;
    setVariationCount(count);
  };

  const removeVariation = async (id) => {
    var idNumber = id++;

    var newVariants = [...variants];
    var newVariations = [...variations];

    newVariants.splice(idNumber, 1);
    newVariations.splice(idNumber, 1);

    setVariants(newVariants);
    setVariations(newVariations);

    var reduce = variation_count - 1;
    setVariationCount(reduce);
  };

  const extractOptions = (index, value) => {
    let vari = variations;
    vari[index] = value;
    setVariations([...vari]);
  };

  const extractVariants = (index, value) => {
    let variant = [...variants];
    variant[index] = value;
    setVariants(variant);
  };

  const exist = (list, item) => {
    var search = list.find((element) => {
      if (element.variant === item.value) {
        return true;
      }
      return false;
    });

    return search;
  };

  const formatVariations = () => {
    var formats = [];

    variations.map((options, index) => {
      var group = variants[index];
      var value = options;

      if (group && options.length > 0) {
        var variation_holder = [],
          counter = 0;

        if (index === 0) {
          value.map((details, i) => (formats[i] = details));
        } else {
          formats.map((format) => {
            value.map((details) => {
              var mapped = {
                value: format.value + "/" + details.value,
                label: format.label + " / " + details.label,
              };

              if (
                !(variation_holder.indexOf(mapped) > -1) &&
                !exist(productVariationOptions, mapped)
              ) {
                variation_holder[counter] = mapped;
                counter++;
              }
            });
          });

          formats = variation_holder;
        }
      }
    });
    setFormatted(formats);
  };

  const handleProductDetailsChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setProduct({ ...product, [name]: value });
  };

  const handleProductInventoryChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setInventory({ ...inventory, [name]: value });
  };

  const handleProductSeoChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setSettings({ ...seo, [name]: value });
  };

  const setProductType = (e) => {
    if ($(e.target).prop("checked") === true) {
      return setProduct({ ...product, type: "physical" });
    }

    setProduct({ ...product, type: "digital" });
  };

  const handleFormCheck = (e, setter) => {
    if ($(e.target).prop("checked") === true) {
      setter(1);
    } else {
      setter(0);
    }
  };

  const save = (e) => handleProductSubmit(e);
  const saveAndClose = (e) => handleProductSubmit(e, true);

  const handleProductSubmit = (e, close = false) => {
    if (null !== e) {
      e.preventDefault();
    }
    if (
      store.store_type === 'live' &&
      subscription.settings.maxProductsAllowed !== "unlimited" &&
      props.mode === "new"
    ) {
      if (
        Number(recordCounts?.products) + 1 >
        subscription.settings.maxProductsAllowed
      ) {
        return handleUpgradeModalShow(
          "Upgrade Your Plan",
          "You have reached the maxiumun number of products allowed for your current plan, kindly upgrade now to add more products."
        );
      }
    }

    if (ValidateSubmit(false, true)) {
      setSaving(true);

      var formdata = new FormData(document.getElementById("ProductUploadForm"));
      formdata.append("store_id", store.id);
      formdata.append("description", description);
      formdata.append("featured", featured);
      formdata.append("publish", publish);
      formdata.append("publish_to_sales_channels", publishToChannels);
      formdata.append("type", product.type);
      formdata.append("google_product_category", selectGoogleCategory?.value);
      formdata.append("video", videoUrl);

      formdata.append("collections", JSON?.stringify(collectionSlugs));

      for (var key in settings) {
        formdata.append(key, settings[key]);
      }

      var hash_tags = [];
      hashtags.map((data) => hash_tags.push(data.value));

      formdata.append("hash_tags", hash_tags.join(","));

      if (props?.mode === "edit") {
        formdata.append("id", product.id);
      }

      if (props?.mode === "new") {
        formdata.append("main", images.main);
        formdata.append("gallery", images.gallery);
      }

      if (formatted) {
        formdata.append("variations", JSON.stringify(variants));
        formdata.append("variation_options", JSON.stringify(variations));
      }

      var data = formdata;
      var _url;
      if (props.mode === "new") {
        _url = `/product/create`;
      } else if (props.mode === "edit") {
        _url = `/product/update`;
      }

      axios
        .post(_url, data)
        .then((response) => {
          if (response?.data?.success) {
            localStorage.setItem("images", []);

            setFormatted([]);
            setSaving(false);
            getProductDetails();
            responseMessage(response?.data?.message, "success");

            // Call batch request endpoint for sales channel here

            if (props?.mode === "new" || close === true) {
              navigate("/products");
            }
          }
        })
        .catch((error) => {
          setSaving(false);

          if (error?.response) {
            return errorResponse(error?.response);
          }

          return responseMessage(
            "Something went wrong, please try again",
            "error"
          );
        });
    }
  };

  const deleteVariant = async () => {
    try {
      const response = await axios.delete(
        `/product/variant/remove/${product_id}/${variantSelectedForRemoval}`
      );
      if (response?.data?.success) {
        setVariantSelectedForRemoval(null);
        getProductVaritions();
        responseMessage(response?.data?.message, "success");
      }
    } catch (error) {
      if (error?.response) return errorResponse(error?.response);

      return responseMessage("Something went wrong, please try again", "error");
    }
  };

  const removeVariantOption = () => {
    return (
      <DeleteModal
        show={variantSelectedForRemoval ? true : false}
        title={"Delete confirmation"}
        message={"Are you sure you want to delete this variation item?"}
        handleDelete={deleteVariant}
        handleClose={() => setVariantSelectedForRemoval(null)}
      />
    );
  };

  const onUploadResponse = (resp) => {
    var $images = {};

    if (resp?.success === true) {
      if (resp?.direct === true) {
        $images = {
          main: resp?.images.main,
          gallery: resp.images.gallery,
        };
      } else if (resp?.direct === false) {
        let storedImages = localStorage.getItem("images");
        var newimagelist = [];

        if (storedImages !== null && storedImages !== "") {
          storedImages = JSON.parse(storedImages);
          newimagelist = storedImages.concat(resp.images);
        } else {
          newimagelist = resp.images;
        }

        localStorage.setItem("images", JSON.stringify(newimagelist));

        $images = {
          main: newimagelist[0],
          gallery: [],
        };

        var sliced = newimagelist.slice(1);
        if (sliced.length > 0) {
          $images.gallery = sliced;
        }
      }

      setProductImages($images);

      $("#uploadStatus").html(
        '<p style="color:#28A74B;">File has uploaded successfully!</p>'
      );
    } else if (resp.success === false) {
      $("#uploadStatus").html(
        '<p style="color:#EA4335;">Please select a valid file to upload.</p>'
      );
    }

    $(".progress").addClass("hide");
    $(".progress-bar").width("0%");
  };

  const onUploadError = (error) => {
    if (error.responseJSON) {
      $("#uploadStatus").html(
        `<p style="color:#EA4335;">${error.responseJSON.message}</p>`
      );
    } else {
      $("#uploadStatus").html(
        '<p style="color:#EA4335;">File upload failed, please try again.</p>'
      );
    }

    $(".progress").addClass("hide");
    $(".progress-bar").width("0%");
  };

  const connectedChannelLogos = connectedChannels.map((channel, index) => {
    switch (channel.sales_channel_slug) {
      case "google":
        return (
          <img
            src="https://res.cloudinary.com/dp7quxpsq/image/upload/v1704205875/zqn1dtmvqlilci0ztskt.png"
            width={20}
            alt="Google"
            key={index}
          />
        );
      case "meta":
        return (
          <img
            src="https://res.cloudinary.com/dp7quxpsq/image/upload/v1704208156/zovblm00rfziesbhsvjs.png"
            width={20}
            alt="Meta"
            key={index}
          />
        );
      case "pinterest":
        return (
          <img
            src="https://res.cloudinary.com/dp7quxpsq/image/upload/v1704208290/h2ioctcy0mtnvoyfzv6w.png"
            width={20}
            alt="Pinterest"
            key={index}
          />
        );
      case "tiktok-commerce":
        return (
          <img
            src="https://res.cloudinary.com/dp7quxpsq/image/upload/v1704223243/kskor3dcqzzjoyjckalu.png"
            width={20}
            alt="Tiktok"
            key={index}
          />
        );
      default:
        break;
    }
  });

  const handleCollectionChange = (newValue) => {
    setSelectedCollectionOptions(newValue);
    const newCategories = newValue?.map((category) => category?.value);

    setCollectionSlugs(newCategories);
  };

  return (
    <>
      {removeVariantOption()}
      <div className="page-content">
        <div className="container-fluid">
          {!loading ? (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    {props.mode === "new" ? (
                      <h4 className="mb-sm-0">Create Product</h4>
                    ) : (
                      <h4 className="mb-sm-0">Edit Product</h4>
                    )}

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="#" onClick={() => window.history.back()}>
                            <i className="ri-arrow-left-line align-bottom me-1"></i>
                            Back
                          </Link>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <form id="ProductUploadForm">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Product Title
                          </label>
                          <input
                            type="text"
                            name="name"
                            validatefield="true"
                            validationoutput="#titleError"
                            validationmessage="Product title cannot be empty!*"
                            value={product.name}
                            onChange={(e) => {
                              handleProductDetailsChange(e);
                            }}
                            className="form-control"
                            id="product-title-input"
                            placeholder="Enter product title"
                          />
                          <span id="titleError"></span>
                        </div>
                        <div>
                          <label>Product Description </label>
                          <TextEditor
                            value={description}
                            setValue={setDescription}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Product Gallery</h5>
                        <p className="text-muted m-0 p-0">
                          Add Product Images. <br />
                          <small style={{ color: "red" }}>
                            <b>Recommended: </b> Image size should not be larger
                            than 500KB for better storefront performance and SEO
                            optimization
                          </small>
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {images.main ? (
                            <div className="col-md-5">
                              <div
                                className="bg-light rounded img-display"
                                style={{ height: "300px", overflow: "hidden" }}
                              >
                                <div className="img-display__overlay">
                                  <span
                                    className="img-display__overlay__remove-btn"
                                    onClick={removeMainImage}
                                  >
                                    <FiTrash2 size={15} />
                                  </span>
                                </div>
                                <img
                                  data-dz-thumbnail
                                  className="img-fluid rounded d-block"
                                  src={images.main}
                                  width={"100%"}
                                  alt="Product"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-5">
                              <MyDropzone
                                endpoint={"/upload-product-images"}
                                message="Select images"
                                entries={
                                  product_id !== undefined
                                    ? { product_id }
                                    : null
                                }
                                beforeUpload={() => {
                                  $("#uploadStatus").html("");

                                  $(".progress").removeClass("hide");
                                  $(".progress-bar").width("0%");
                                }}
                                onUploadResponse={onUploadResponse}
                                onUploadError={onUploadError}
                              />
                            </div>
                          )}

                          <div className="col-md-7">
                            <div className="row">
                              {images.gallery.length > 0 ? (
                                <>
                                  {images.gallery.map((image, index) => (
                                    <div
                                      className={
                                        "col-4 p-0 ps-1 pe-1 " +
                                        (index < 2 ? "mb-2" : "")
                                      }
                                      key={index}
                                    >
                                      <div className="img-thumb">
                                        <div
                                          className="bg-light rounded img-display"
                                          style={{
                                            height: 145,
                                            overflow: "hidden",
                                          }}
                                        >
                                          <div className="img-display__overlay">
                                            <span
                                              className="img-display__overlay__remove-btn"
                                              onClick={() => {
                                                removeGalleryImage(index);
                                              }}
                                            >
                                              <FiTrash2 size={15} />
                                            </span>
                                          </div>
                                          <img
                                            data-dz-thumbnail
                                            className="img-fluid rounded d-block"
                                            src={image}
                                            alt="Product"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}

                              {images.main && images.gallery.length < 5 ? (
                                <div className="col-4 p-1">
                                  <MyDropzone
                                    endpoint={"/upload-product-images"}
                                    message="Add images"
                                    size={"small"}
                                    entries={{ product_id }}
                                    beforeUpload={() => {
                                      $("#uploadStatus").html("");

                                      $(".progress").removeClass("hide");
                                      $(".progress-bar").width("0%");
                                    }}
                                    onUploadResponse={onUploadResponse}
                                    onUploadError={onUploadError}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="progress mt-2 hide">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: 0 }}
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            0%
                          </div>
                        </div>
                        <div
                          id="uploadStatus"
                          className="mt-2 text-center"
                        ></div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Product Video</h5>
                        <p className="text-muted m-0 p-0">
                          Add Product Videos. <br />
                          <small style={{ color: "red" }}>
                            <b>Recommended: </b> Video size should not be larger
                            than 2mb for better storefront performance and SEO
                            optimization
                          </small>
                        </p>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <UploadWidget />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <ul
                          className="nav nav-tabs-custom card-header-tabs border-bottom-0"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#addproduct-general-info"
                              role="tab"
                            >
                              Inventory Info
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#addproduct-metadata"
                              role="tab"
                            >
                              SEO Meta Data
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="card-body">
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="addproduct-general-info"
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="product-brand-input"
                                  >
                                    Brand Name
                                  </label>
                                  <input
                                    type="text"
                                    name="brand"
                                    value={product.brand}
                                    onChange={(e) =>
                                      handleProductDetailsChange(e)
                                    }
                                    className="form-control"
                                    id="product-brand-input"
                                    placeholder="Enter brand"
                                    aria-label="Brand"
                                    aria-describedby="product-brand-addon"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="manufacturer-name-input"
                                  >
                                    Condition
                                  </label>
                                  <select
                                    name="condition"
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-select"
                                    id="condition-input"
                                  >
                                    <option value={""}>Select condition</option>
                                    <option
                                      selected={inventory.condition === "new"}
                                      value={"new"}
                                    >
                                      New
                                    </option>
                                    <option
                                      selected={inventory.condition === "used"}
                                      value={"used"}
                                    >
                                      Used
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="manufacturer-name-input"
                                  >
                                    Manufacturer Name
                                  </label>
                                  <input
                                    type="text"
                                    name="manufacturer"
                                    value={inventory.manufacturer}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="manufacturer-name-input"
                                    placeholder="Enter manufacturer name"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="manufacturer-number-input"
                                  >
                                    Manufacturer Part Number
                                  </label>
                                  <input
                                    type="text"
                                    name="manufacturer_part_number"
                                    value={inventory.manufacturer_part_number}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="manufacturer-number-input"
                                    placeholder="Enter manufacturer number"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="product-barcode-input"
                                  >
                                    Barcode (UPC, EAN, ISBN, JAN, etc.){" "}
                                    <IconWithTooltip
                                      message={`Unique identifier or GTIN is a unique and internationally recognized identifier for a product. When a GTIN is available, it is located under the barcode on your product's packaging or cover.`}
                                    >
                                      <small className="text-danger">
                                        <i className="ri-information-line"></i>
                                      </small>
                                    </IconWithTooltip>
                                  </label>
                                  <input
                                    type="text"
                                    name="barcode"
                                    value={inventory.barcode}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="product-barcode-input"
                                    placeholder="Barcode"
                                    aria-label="Barcode"
                                    aria-describedby="product-barcode-addon"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="product-price-input"
                                      >
                                        Price
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="product-price-addon"
                                        >
                                          {currency}
                                        </span>
                                        <input
                                          type="text"
                                          name="price"
                                          value={product.price}
                                          onChange={(e) =>
                                            setProduct({
                                              ...product,
                                              price: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                          id="product-price-input"
                                          placeholder="Enter price"
                                          aria-label="Price"
                                          aria-describedby="product-price-addon"
                                          validatefield="true"
                                          validationoutput="#priceError"
                                          validationmessage="required*"
                                        />
                                      </div>
                                      <span id="priceError"></span>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="product-discount-input"
                                      >
                                        Discount
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="product-discount-addon"
                                        >
                                          %
                                        </span>
                                        <input
                                          type="text"
                                          name="discount"
                                          value={product.discount}
                                          onChange={(e) =>
                                            handleProductDetailsChange(e)
                                          }
                                          className="form-control"
                                          id="product-discount-input"
                                          placeholder="Enter discount"
                                          aria-label="discount"
                                          aria-describedby="product-discount-addon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="sku-brand-input"
                                  >
                                    Store keeping unit (SKU)
                                  </label>
                                  <input
                                    type="text"
                                    name="sku"
                                    value={inventory.sku}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="sku-brand-input"
                                    placeholder="Enter SKU"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="product-quantity-input"
                                      >
                                        Quantity
                                      </label>
                                      <input
                                        type="number"
                                        name="quantity"
                                        value={product.quantity}
                                        onChange={(e) =>
                                          handleProductDetailsChange(e)
                                        }
                                        className="form-control"
                                        id="product-quantity-input"
                                        placeholder=""
                                        aria-label="quantity"
                                        aria-describedby="product-quantity-addon"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="product-cost-input"
                                      >
                                        Cost/Unit
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="product-cost-addon"
                                        >
                                          {currency}
                                        </span>
                                        <input
                                          type="text"
                                          name="cost_price"
                                          value={product.cost_price}
                                          onChange={(e) =>
                                            handleProductDetailsChange(e)
                                          }
                                          className="form-control"
                                          id="product-cost-input"
                                          placeholder="Enter cost"
                                          aria-label="Cost"
                                          aria-describedby="product-cost-addon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane"
                            id="addproduct-metadata"
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="meta-title-input"
                                  >
                                    Meta title
                                  </label>
                                  <input
                                    type="text"
                                    name="meta_title"
                                    value={seo.meta_title}
                                    onChange={(e) => handleProductSeoChange(e)}
                                    className="form-control"
                                    placeholder="Enter meta title"
                                    id="meta-title-input"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="meta-keywords-input"
                                  >
                                    Meta Keywords
                                  </label>
                                  <input
                                    type="text"
                                    value={seo.meta_keywords}
                                    onChange={(e) => handleProductSeoChange(e)}
                                    name="meta_keywords"
                                    className="form-control"
                                    placeholder="Enter meta keywords"
                                    id="meta-keywords-input"
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <label
                                className="form-label"
                                htmlFor="meta-description-input"
                              >
                                Meta Description
                              </label>
                              <textarea
                                name="meta_description"
                                value={seo.meta_description}
                                onChange={(e) => handleProductSeoChange(e)}
                                className="form-control"
                                id="meta-description-input"
                                placeholder="Enter meta description"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Shipping</h5>
                      </div>
                      <div className="card-body">
                        <div className="mt-3 mb-4">
                          <div className="form-check">
                            <input
                              onChange={(e) => setProductType(e)}
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck2"
                              checked={product.type === "physical"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck2"
                            >
                              This is a physical product
                            </label>
                          </div>
                        </div>

                        {product?.type === "physical" && (
                          <Fragment>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="weight-name-input"
                                  >
                                    Weight (kg)
                                  </label>
                                  <input
                                    type="text"
                                    name="weight"
                                    value={inventory.weight}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="weight-input"
                                    placeholder="Enter weight (in kg e.g 10)"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="length-name-input"
                                  >
                                    Length (cm)
                                  </label>
                                  <input
                                    type="text"
                                    name="length"
                                    value={inventory.length}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="length-input"
                                    placeholder="Enter length (in cm e.g 10)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="width-name-input"
                                  >
                                    Width (cm)
                                  </label>
                                  <input
                                    type="text"
                                    name="width"
                                    value={inventory.width}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="width-input"
                                    placeholder="Enter width (in cm e.g 10)"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="height-name-input"
                                  >
                                    Height (cm)
                                  </label>
                                  <input
                                    type="text"
                                    name="height"
                                    value={inventory.height}
                                    onChange={(e) =>
                                      handleProductInventoryChange(e)
                                    }
                                    className="form-control"
                                    id="height-input"
                                    placeholder="Enter height (in cm e.g 10)"
                                  />
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Variations</h5>
                      </div>

                      <div className="card-body">
                        <div className="VariationsBox p-2 ">
                          {[...Array(variation_count)].map((count, index) => {
                            return (
                              <VariationField
                                variant={variants[index]}
                                options={variations[index]}
                                variationTypes={variationTypes}
                                extractVariants={extractVariants}
                                extractOptions={extractOptions}
                                removeVariation={removeVariation}
                                id={index}
                                key={"var--field-" + index}
                              />
                            );
                          })}
                        </div>
                        <div className="mb-3">
                          <button
                            type="button"
                            onClick={addVariation}
                            className="btn btn-secondary w-sm "
                          >
                            Add Variations
                          </button>
                        </div>

                        {/* Variation List Section */}

                        {formatted.length > 0 ? (
                          <div className="border-top row">
                            <h5 className="card-title m-0 mt-4 mb-3">
                              New Item Options
                            </h5>
                            {formatted.map((variant, index) => (
                              <>
                                {!exist(productVariationOptions, variant) ? (
                                  <Variant
                                    variant={variant}
                                    product={product}
                                    key={index}
                                    mode={props?.mode}
                                  />
                                ) : null}
                              </>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {productVariationOptions.length > 0 ? (
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-0">Variation Options</h5>
                        </div>
                        <div className="card-body">
                          <div className="VariationsBox p-2 "></div>
                          {productVariationOptions.length > 0 ? (
                            <div className="mt-2 border-top row">
                              {productVariationOptions.map((option, index) => (
                                <VariationOption
                                  option={option}
                                  images={images}
                                  key={index}
                                  index={index}
                                  productVariationOptions={
                                    productVariationOptions
                                  }
                                  setProductVariationOptions={
                                    setProductVariationOptions
                                  }
                                  setVariantSelectedForRemoval={
                                    setVariantSelectedForRemoval
                                  }
                                />
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Collection</h5>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          {product.collection ? (
                            <span className="badge badge-label bg-primary">
                              <i className="mdi mdi-circle-medium"></i>
                              {product.collection}
                            </span>
                          ) : null}
                        </div>
                        <div>
                          <div className="mb-2">
                            <Form.Group>
                              <Form.Label>Select Collections</Form.Label>
                              <AsyncSelect
                                isClearable
                                isMulti
                                defaultOptions={collectionOption}
                                onChange={handleCollectionChange}
                                defaultValue={seletedCollectionOptions}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Publish Settings</h5>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <label htmlFor="availability" className="form-label">
                            Availability
                          </label>

                          <select
                            className="form-select"
                            name="availability"
                            id="availability"
                          >
                            {availability_types.map((data, index) =>
                              product.availability === data.id ? (
                                <option
                                  value={data.id}
                                  key={index}
                                  selected={data.id}
                                >
                                  {data.label}
                                </option>
                              ) : (
                                <option value={data.id} key={index}>
                                  {data.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="mt-3">
                          <div className="form-check">
                            <input
                              onChange={(e) => handleFormCheck(e, setPublish)}
                              className="form-check-input"
                              type="checkbox"
                              id="toStorefront"
                              checked={publish}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="toStorefront"
                            >
                              Publish to Storefront
                            </label>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="form-check">
                            <input
                              onChange={(e) =>
                                handleFormCheck(e, setPublishToChannels)
                              }
                              className="form-check-input"
                              type="checkbox"
                              id="toChannel"
                              checked={publishToChannels}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="toChannel"
                            >
                              Publish to Sale Channels
                              {/* Image of sales channels here */}
                            </label>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="form-check">
                            <input
                              onChange={(e) => handleFormCheck(e, setFeatured)}
                              className="form-check-input"
                              type="checkbox"
                              id="toFeaturedProduct"
                              checked={featured}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="toFeaturedProduct"
                            >
                              Add to Featured Products
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {connectedChannels.length > 0 && (
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <h5 className="card-title mb-0">Sales Channel</h5>
                            <div className="d-flex">
                              {connectedChannelLogos}
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="form-group">
                            <label
                              className="form-check-label"
                              htmlFor="toFeaturedProduct"
                            >
                              Select Google Product Category
                            </label>
                            {checkChannelStatus("google") ? (
                              <CreatableSingle
                                selected={selectGoogleCategory}
                                options={googleProductCategories}
                                handleSelectOptions={(id, value) =>
                                  setSelectedGoogleCategory(value)
                                }
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="card d-none">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Publish Schedule</h5>
                      </div>

                      <div className="card-body">
                        <div>
                          <label
                            htmlFor="datepicker-publish-input"
                            className="form-label"
                          >
                            Publish Date & Time
                          </label>
                          <div className="row g-3 mb-0 align-items-center">
                            <div className="col-sm-auto">
                              <div className="input-group">
                                <Flatpickr
                                  type="text"
                                  className="form-control border-0 shadow"
                                  id="dash-filter-picker"
                                  defaultValue={initialDate}
                                  options={{ mode: "single", enableTime: true }}
                                  onChange={(selectedDates) => {
                                    if (selectedDates) {
                                      var date = moment(selectedDates[0]);
                                      setPublishDate(
                                        date.format("YYYY-MM-DD HH:mm:ss")
                                      );
                                    }
                                  }}
                                />
                                <div className="input-group-text bg-primary border-primary text-white">
                                  <i className="ri-calendar-2-line"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Product Tags</h5>
                      </div>
                      <div className="card-body">
                        <div className="hstack gap-3 align-items-start">
                          <div className="flex-grow-1">
                            <CreatableInputOnly
                              id={0}
                              defaultValues={hashtags}
                              extractOptions={extractHashTags}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">
                          Product Care Information
                        </h5>
                      </div>
                      <div className="card-body">
                        <p className="text-muted mb-2">
                          Add care information for product
                        </p>
                        <textarea
                          className="form-control"
                          name="care_information"
                          value={inventory.care_information}
                          onChange={(e) => handleProductInventoryChange(e)}
                          placeholder="Must enter minimum of a 100 characters"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <div className="text-end mb-3">
                      {props?.mode === "new" ? (
                        <>
                          <button
                            type="button"
                            onClick={(e) => saveAndClose(e)}
                            className={`btn ms-1 btn-success w-sm ${saving ? "disabled" : ""
                              }`}
                          >
                            {saving ? (
                              <>
                                <div
                                  className="spinner-border spinner-border-sm text-white"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>{" "}
                                Saving...
                              </>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={(e) => save(e)}
                          disabled={saving}
                          className={`btn btn-primary ms-2 w-sm ${saving ? "disabled" : ""
                            }`}
                        >
                          {saving ? (
                            <>
                              <div
                                className="spinner-border spinner-border-sm text-white"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>{" "}
                              Saving...
                            </>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              <div className="mb-2">
                <Placeholder column="col-9" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-12" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-7" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-10" />
              </div>
            </>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default ProductUpload;
