import "simplebar/dist/simplebar.min.css";

import React, { Fragment, useContext } from "react";

import { AppContext } from "../../Contexts/AppContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { Link } from "react-router-dom";
import LogoDark from "./Logos/LogoDark";
import LogoLight from "./Logos/LogoLight";
import SimpleBar from "simplebar-react";

const NavMenu = () => {
  const { toggleSidebar } = useContext(AppContext);
  const { user, logoutUser } = useContext(AuthContext);

  const toggleSidebarOnMobileOnly = () => {
    if (window.innerWidth <= 480) {
      toggleSidebar();
    }
  };

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <LogoDark />
        <LogoLight />
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <SimpleBar id="scrollbar" className="h-100">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link menu-link"
                onClick={toggleSidebarOnMobileOnly}
              >
                <i className="ri-home-line"></i>
                <span data-key="t-dashboards">Home</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/sales-and-orders"
                className="nav-link menu-link"
                onClick={toggleSidebarOnMobileOnly}
              >
                <i className="ri-shopping-bag-3-line"></i>
                <span data-key="t-orders">Orders</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#productsInventory"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="productsInventory"
              >
                <i className="ri-price-tag-3-line"></i>
                <span data-key="t-layouts">Products</span>
              </Link>
              <div className="collapse menu-dropdown" id="productsInventory">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/products"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      All Products
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/products/create"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Add Products
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/collections"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Collections
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                to="/customers"
                className="nav-link menu-link"
                onClick={toggleSidebarOnMobileOnly}
              >
                <i className="ri-contacts-line"></i>
                <span data-key="t-customers">Customers</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#payments"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="payments"
              >
                <i className="ri-bank-card-line"></i>
                <span data-key="t-layouts">Payments</span>
              </Link>
              <div className="collapse menu-dropdown" id="payments">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/payments/links"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Payment Links
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/payments/transactions"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Transactions
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/payments/wallets"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      My Wallets
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#marketing"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="marketing"
              >
                <i className="ri-bank-card-line"></i>
                <span data-key="t-layouts">Marketing</span>
              </Link>
              <div className="collapse menu-dropdown" id="marketing">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/marketing/subscribers"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Subscribers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/marketing/campaigns"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Campaigns
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/marketing/automations"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Automations
                    </Link>
                  </li> */}
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                to="/analytics"
                className="nav-link menu-link"
                onClick={toggleSidebarOnMobileOnly}
              >
                <i className="mdi mdi-chart-line"></i>
                <span data-key="t-dashboards">Analytics</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/coupons"
                className="nav-link menu-link"
                onClick={toggleSidebarOnMobileOnly}
              >
                <i className="ri-percent-line"></i>
                <span data-key="t-coupons">Discounts</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/invoices"
                className="nav-link menu-link"
                onClick={toggleSidebarOnMobileOnly}
              >
                <i className="ri-file-list-2-line"></i>
                <span data-key="t-orders">Invoicing</span>
              </Link>
            </li>

            <li className="menu-title">
              <i className="ri-more-fill"></i>
              <span data-key="t-channels">Sales Channels</span>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#OnlineStoreDropdown"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="OnlineStoreDropdown"
              >
                <i className="ri-store-2-line"></i>
                <span data-key="t-storefront">Online Store</span>
              </Link>
              <div className="collapse menu-dropdown" id="OnlineStoreDropdown">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/settings/online-store/themes"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Themes
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/settings/online-store/navigations"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Navigations
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/settings/online-store/pages"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Pages
                    </Link>
                  </li>
                  <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#BlogDropdown"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="BlogDropdown"
              >
                {/* <i className="ri-store-2-line"></i> */}
                <span data-key="t-storefront">Blog Posts</span>
              </Link>
              <div className="collapse menu-dropdown" id="BlogDropdown">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/blogs"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      All Blogs Posts
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="blogs/create"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Add Blog Post
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
         
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                to="/settings/sales-channels"
                className="nav-link menu-link"
                onClick={toggleSidebarOnMobileOnly}
              >
                <i className="ri-exchange-dollar-line"></i>
                <span data-key="t-customers">Sales Channels</span>
              </Link>
            </li>

            <li className="menu-title">
              <i className="ri-more-fill"></i>
              <span data-key="t-settings">Settings & Integration</span>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#sidebarLayouts"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarLayouts"
              >
                <i className="ri-settings-2-line"></i>
                <span data-key="t-layouts">Store Settings</span>
              </Link>
              <div className="collapse menu-dropdown" id="sidebarLayouts">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/settings/store-setup"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      General Info
                    </Link>
                  </li>
                  {user.is_vendor === 1 && (
                    <Fragment>
                      <li className="nav-item">
                        <Link
                          to="/settings/staffs"
                          className="nav-link"
                          onClick={toggleSidebarOnMobileOnly}
                        >
                          Staff Accounts
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/settings/payment-setup"
                          className="nav-link"
                          onClick={toggleSidebarOnMobileOnly}
                        >
                          Payments Setup
                        </Link>
                      </li>
                    </Fragment>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/settings/delivery"
                      className="nav-link"
                      onClick={toggleSidebarOnMobileOnly}
                    >
                      Delivery Setup
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {user.is_vendor === 1 && (
              <Fragment>
                {/* <li className="nav-item">
                  <Link
                    to="/settings/developer-settings"
                    className="nav-link menu-link"
                    onClick={toggleSidebarOnMobileOnly}
                  >
                    <i className="mdi mdi-code-tags fs-18"></i>
                    <span data-key="t-developer-settings">Developer Settings</span>
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    to="/referrals"
                    className="nav-link menu-link"
                    onClick={toggleSidebarOnMobileOnly}
                  >
                    <i className="mdi mdi-cash fs-18"></i>
                    <span data-key="t-referrals">Refer & Earn</span>
                  </Link>
                </li>
              </Fragment>
            )}

            <li className="nav-item">
              <Link className="nav-link menu-link" onClick={logoutUser}>
                <i className="ri-logout-circle-line"></i>
                <span data-key="t-logout">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </div>
  );
};

export default NavMenu;
