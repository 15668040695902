import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useState } from 'react'
import { authData, errorResponse, responseMessage } from '../libs/app';
import { AppContext } from './AppContext';
import UpgradeModal from '../Components/Subscription/UpgradeModal';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setCookie } from 'wearslot-dev-utils';
import { AES } from 'crypto-js';
import CryptoJS from 'crypto-js';

export const AuthContext = React.createContext();


const SplashScreen = () => {
    return (
        <div className='col-md-12'>
            <div className='d-flex justify-content-center align-items-center vh-100'>
                <lord-icon
                    src="https://cdn.lordicon.com/xjovhxra.json"
                    trigger="loop"
                    colors="primary:#695eef,secondary:#18151e"
                    style={{ width: "120px", height: "120px" }}
                >
                </lord-icon>
            </div>
        </div>
    );
}

export const logoutUser = (next = true) => {
    localStorage.clear();
    if (next) {
        setCookie("next", window.location.href);
    }
    window.location = (process.env.REACT_APP_AUTH_URL + '?logout=true');
}

const AuthProvider = ({ children }) => {

    const token = window.token;
    const { settings, setSettings } = React.useContext(AppContext);
    const { loggedInUser, storeDetails } = authData();
    const [user, setUser] = React.useState(loggedInUser)
    const [store, setStore] = React.useState(storeDetails);
    const [selectedWallet, setSelectedWallet] = React.useState();
    const [wallets, setWallets] = React.useState([]);
    const [subscription, setSubscription] = React.useState({});
    const [recordCounts, setRecordCounts] = React.useState(null);
    const [authenticated, setAuthenticated] = React.useState(false);
    const [currency, setCurrency] = React.useState(getSymbolFromCurrency(storeDetails && storeDetails.wallet?.currency?.abbr));
    const [connectedProviders, setConnectedProviders] = React.useState([]);

    const [showUpgradeModal, setShowUpgradeModal] = React.useState(false);
    const [upgradeRequirements, setUpgradeRequirements] = React.useState('');
    const [upgradeMessage, setUpgradeMessage] = React.useState('');
    const [allowClose, setAllowClose] = React.useState(true)

    const [storeFrontToken, setStorefrontToken] = useState(null);

    const [currencies, setCurrencies] = React.useState([
        { name: 'Nigerian Naira', abbr: 'NGN' },
        { name: 'United States Dollars', abbr: 'USD' },
        { name: 'Great Britain Pounds', abbr: 'GBP' },
        { name: 'Euro', abbr: 'EUR' },
    ]);

    const navigator = useNavigate();
    const { onboarding } = useSearchParams();
    const [onboardingMode, setOnboardingMode] = useState(Boolean(onboarding))

    React.useEffect(() => {
        getAuthCredentials()
    }, []);

    React.useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            navigator('/dashboard/access/choose/plan');
        }
    }, [subscription, store])

    async function getAuthCredentials() {
        try {
            const authCredendtials = axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/account/get-auth-details`);
            const response = (await authCredendtials).data;

            if (response.success) {

                if (response.data.user && response.data.store) {

                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    localStorage.setItem("store", JSON.stringify(response.data.store));
                    localStorage.setItem("settings", JSON.stringify(response.data.settings))

                    setUser(response.data.user)
                    setStore(response.data.store)
                    setSettings({ ...settings, ...response.data.settings })
                    setCurrency(getSymbolFromCurrency(response.data.store.wallet.currency.abbr))

                    await getSubscription();
                    await getWallets();

                    if (response.data.store.store_type === 'development') {
                        const getDSTToken = await axios.get(`/settings/storefront?names=default_store_front_token`);
                        if (getDSTToken.data.success) {
                            setStorefrontToken(getDSTToken.data.data.default_store_front_token);
                            const token =  AES.encrypt(getDSTToken.data.data.default_store_front_token, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();
                            setCookie('_dt_stk_', token, 100, process.env.REACT_APP_DOMAIN)
                        }
                    }

                    setAuthenticated(true);
                    return true
                }
            }
        } catch (error) {
            logoutUser(true);
        }

        return false;
    }

    async function getSubscription() {

        try {
            const response = await axios.get('/subscription');

            if (response.data.success) {
                setSubscription(response.data.data.subscription)
                setRecordCounts(response.data.data.record_counts)
            }
        } catch (error) {
        }
    }

    async function getWallets() {

        try {
            const response = await axios.get('/wallets');
            if (response.data.success) {
                response.data.data.map(wallet => wallet.is_default == 1
                    && (
                        setCurrency(getSymbolFromCurrency(wallet.currency.abbr))
                    ));
                setWallets(response.data.data);
            }

        } catch (error) {
        }
    }

    async function getConnectedDeliveryProviders() {
        try {
            const response = await axios.get('/settings/delivery/connected-providers')
            if (response.data.success) {
                setConnectedProviders(response.data.data)
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    const displayCurrency = selectedWallet ? getSymbolFromCurrency(selectedWallet?.currency?.abbr) : currency;

    const handleUpgradeModalShow = (requirements, message, allowedClose = true) => {
        setUpgradeRequirements(requirements)
        setUpgradeMessage(message)
        setAllowClose(allowedClose)
        setShowUpgradeModal(true)
    }

    const handleUpgradeModalClose = () => {
        setShowUpgradeModal(false);
        setUpgradeRequirements('')
        setUpgradeMessage('')
    }

    return (
        <AuthContext.Provider
            value={{
                token, currency, user, setUser, store, setStore, onboarding, onboardingMode, displayCurrency, connectedProviders, storeFrontToken,
                subscription, setSubscription, recordCounts, getSubscription, currencies, getConnectedDeliveryProviders,
                handleUpgradeModalShow, wallets, getWallets, selectedWallet, setSelectedWallet, getAuthCredentials, setOnboardingMode,
                logoutUser
            }}>
            {authenticated ?
                <>
                    {children}
                    <UpgradeModal
                        show={showUpgradeModal}
                        allowClose={allowClose}
                        upgradeRequirements={upgradeRequirements}
                        upgradeMessage={upgradeMessage}
                        handleClose={handleUpgradeModalClose}
                    />
                </> :
                <SplashScreen />
            }
        </AuthContext.Provider>
    )
}

export default AuthProvider;