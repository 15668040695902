import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthContext";
import { errorResponse, responseMessage } from "../../libs/app";
import ModalBox from "../../Components/ModalBox";
import { Fragment } from "react";
import Loader from "../../Components/Loader/Loader";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { HiChevronDown, HiOutlineCheck, HiOutlineEye } from "react-icons/hi";
import { RiPaletteLine } from "react-icons/ri";
import IconWithTooltip from "../../Components/IconWithTooltip";
import Banners from "./OnlineStore/Themes/Banners";
import Colors from "./OnlineStore/Themes/Colors";
import getSymbolFromCurrency from "currency-symbol-map";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

export async function saveSettings(settings, showResponseMessage = false) {
  try {
    const response = await axios.put("/settings/storefront/save", {
      settings: JSON.stringify(settings),
    });
    if (response.data.success) {
      showResponseMessage && responseMessage(response.data.message, "success");
    }
  } catch (error) {
    if (error.response) errorResponse(error.response);
    else responseMessage("Something went wrong.", "error");
  }
}
export async function savePassword(password) {
  try {
    const response = await axios.put("/settings/storefront/password", {
      password: password,
    });
    if (response.data.success) {
      responseMessage(response.data.message, "success");
    }
  } catch (error) {
    if (error.response) errorResponse(error.response);
    else responseMessage("Something went wrong.", "error");
  }
}

const Storefront = () => {
  document.title = "Themes | Taojaa - Store Manager App";

  const { store, subscription, handleUpgradeModalShow, storeFrontToken } =
    useContext(AuthContext);
  const [modal, setModal] = useState(null);
  const [saving, setSaving] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [themes, setThemes] = useState({
    active_theme: null,
    themes: [],
    loading: true,
  });
  const [themeModal, setThemeModal] = useState(false);

  const [storeTemplates, setStoreTemplates] = useState({
    templates: [],
    state: "default",
    pagination: null,
    sponsored_templates: [],
  });

  const [settings, setStorefrontSettings] = useState({
    google_analytics: false,
    google_analytics_script: null,
    facebook_pixels: false,
    facebook_pixels_script: null,
    store_is_password_protected: false,
  });
  const [adding, setAdding] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const [password, setPassword] = useState("");

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getVendorThemes();
    getStorefrontSettings();
    setPassword(store?.password);
  }, [store, subscription]);

  async function getStorefrontSettings() {
    try {
      const settings =
        "google_analytics,google_analytics_script,facebook_pixels,facebook_pixels_script,store_is_password_protected";
      const response = await axios.get(
        `/settings/storefront?names=${settings}`
      );
      if (response.data.success) {
        setStorefrontSettings(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getVendorThemes() {
    try {
      const response = await axios.get(`/settings/themes`);
      if (response.data.success) {
        setThemes({
          active_theme: response.data.active_theme,
          themes: response.data.themes,
          loading: false,
        });
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }
  }

  async function activateTheme(theme) {
    try {
      const response = await axios.post(
        `/settings/themes/activate/${theme.id}`
      );
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getVendorThemes();
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }
  }

  async function saveIntegration(data) {
    setSaving(true);
    await saveSettings(data, true);

    closeModal();
    setSaving(false);
  }

  async function fetchThemes(page = 1, category = null) {
    setStoreTemplates({ ...storeTemplates, state: "loading" });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEMPLATES_ENDPOINT}/templates?page=${page}${category ? `&category=${category}` : ""
        }`
      );
      if (response.data.status === "success") {
        setStoreTemplates({
          templates: response.data.data.templates,
          sponsored_templates: response.data.data.sponsored_templates,
          state: "ready",
        });
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleFieldChange = (e) => {
    saveIntegration({ [modal]: e.target.value });
  };

  const updateSettings = (name, value) => {
    saveIntegration({ [name]: value });
  };

  const openModal = (target) => {
    if (
      target == "google_analytics_script" ||
      target == "facebook_pixels_script"
    ) {
      if (subscription?.settings.connectedApps === false) {
        return handleUpgradeModalShow(
          "Upgrade Your Plan",
          "Oops! we're sorry this feature is not available on your current plan, upgrade now to continue enjoying these features."
        );
      }
    }

    setModal(target);
  };

  const openThemeModal = async () => {
    setThemeModal(true);

    try {
      await fetchThemes();
    } catch (error) { }
  };

  const closeThemeModal = () => {
    setThemeModal(false);
  };

  const closeModal = () => {
    setModal(null);
  };

  const checkTheme = (template) => {
    if (template.id === themes.active_theme?.template_id) {
      return true;
    }

    const search = themes.themes.find(
      (theme) => theme.template_id === template.id
    );
    return Boolean(search);
  };

  const addTemplate = async (template) => {
    try {
      setAdding(template.id);

      const response = await axios.post(`/settings/themes/add`, {
        template_id: template.id,
      });
      if (response.data.success) {
        getVendorThemes();

        responseMessage(response.data.message, "success");
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    } finally {
      setAdding(null);
    }
  };

  const buyTemplate = (template) => { };

  const VersionSelect = ({ theme }) => {

    return (<div className='d-flex gap-2 align-items-center'>
      <p className='m-0 text-muted'>
        Version:
      </p>
      <div className="dropdown">
        <a href="#" role="button" id="dropdownMenuVersion" className='btn btn-light btn-sm' data-bs-toggle="dropdown" aria-expanded="false">
          {theme.version} <HiChevronDown />
        </a>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuVersion">
          {theme.versions.map((version, index) => <li className='d-flex justify-content-between px-3' key={index}>
            <span>{version.version}</span>
            {(version.version !== theme.version) &&
              <div>
                <IconWithTooltip message={'Preview'}>
                  <a href={`https://${store.slug}.${process.env.REACT_APP_STOREFRONT_DOMAIN}?theme_id=${version.id}${store.store_type === 'development' ? `&key=${storeFrontToken}` : ''}`}
                    target='_blank' className='me-2'>
                    <HiOutlineEye />
                  </a>
                </IconWithTooltip>
                <IconWithTooltip message={'Customize'}>
                  <a
                    href={`${process.env.REACT_APP_EDITOR_URL}/theme/${theme.slug}?version=${version.version}`}
                    target='_blank' className='me-2'><RiPaletteLine />
                  </a>
                </IconWithTooltip>
                <IconWithTooltip message={'Publish'}>
                  <a href={`#`} onClick={() => activateTheme(version)} className='me-2 text-success'>
                    <HiOutlineCheck />
                  </a>
                </IconWithTooltip>
              </div>
            }
          </li>)}
        </ul>
      </div>
    </div>
    );
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Online Store</h4>
              </div>
            </div>
          </div>
          {store.store_type === "development" ? (
            <div
              class="alert alert-primary alert-dismissible fade show"
              role="alert"
            >
              <strong>Notice:</strong> This store is in development mode.
              Visitors will be required to enter a password to access the store.
            </div>
          ) : null}

          <div className="row justify-content-center">
            <div className="col-xxl-9 mb-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Live Theme</h4>
                </div>
                <div className="card-body">
                  <div className="mt-2 d-flex justify-content-center">
                    {themes.loading ? (
                      <div className="py-5">
                        <Loader
                          message={<p className="text-muted">Loading...</p>}
                        />
                      </div>
                    ) : (
                      <Fragment>
                        {themes.active_theme && (
                          <Fragment>
                            <Row>
                              <Col md={12}>
                                <div
                                  className="position-relative theme-card"
                                  style={{ maxHeight: 350, overflow: "hidden" }}
                                >
                                  <img
                                    src={themes.active_theme.image}
                                    width={"100%"}
                                  />
                                </div>
                                <div className="d-flex justify-content-between p-3 align-items-center">
                                  <div className="">
                                    <h6 className="mb-1">
                                      {themes.active_theme.name}{" "}
                                      <small className="badge badge-soft-success text-uppercase">
                                        Active
                                      </small>
                                    </h6>
                                    <VersionSelect
                                      theme={themes.active_theme}
                                    />
                                    <p className="m-0 text-muted">
                                      Added:{" "}
                                      {moment(
                                        themes.active_theme.created_date
                                      ).format("LLLL")}
                                    </p>
                                  </div>
                                  <div className=''>
                                    <div className='d-flex gap-2'>
                                      <a
                                        href={`${process.env.REACT_APP_EDITOR_URL}/theme/${themes.active_theme.slug}?version=${themes.active_theme.version}`}
                                        // href='#'
                                        target="_blank"
                                        className="btn btn-primary btn-sm"
                                      >
                                        <i className="ri-palette-line"></i>{" "}
                                        <span className="desktop">
                                          Customize
                                        </span>
                                      </a>
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          role="button"
                                          id="dropdownMenuLink"
                                          className="btn btn-light btn-sm"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="ri-more-2-fill"></i>
                                        </a>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuLink"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              target="_blank"
                                              href={`https://${store.slug}.${
                                                process.env
                                                  .REACT_APP_STOREFRONT_DOMAIN
                                              }?theme_id=${
                                                themes.active_theme.id
                                              }${
                                                store.store_type ===
                                                "development"
                                                  ? `&key=${storeFrontToken}`
                                                  : ""
                                              }`}
                                            >
                                              Preview
                                            </a>
                                          </li>
                                          {/* <li><a className="dropdown-item" href="#">Edit code</a></li> */}
                                          {/* <li><a className="dropdown-item" href="#">Download theme file</a></li> */}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-9 mb-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1">My Themes</h4>
                  <div className="themesExplorer">
                    <button
                      className="btn btn-light btn-sm"
                      onClick={openThemeModal}
                    >
                      Add New Themes
                    </button>
                    <ModalBox
                      size={"xl"}
                      show={themeModal}
                      handleClose={closeThemeModal}
                      title={"Themes"}
                      closeBtn={
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={closeThemeModal}
                        >
                          {" "}
                          Close
                        </button>
                      }
                    >
                      <div className="templates-section">
                        <div className="row"></div>
                        {storeTemplates.templates.map((template) => (
                          <Fragment>
                            <div className="col-md-3">
                              <div
                                className="template-image"
                                style={{ maxHeight: 200, overflow: "hidden" }}
                              >
                                <img
                                  src={template.image}
                                  alt={template.name}
                                  width={"100%"}
                                />
                              </div>
                              <div className="template-detail d-flex justify-content-between align-items-center pt-2">
                                <div className="details">
                                  <h6 className="mb-1">
                                    <a
                                      // href={`${process.env.REACT_APP_THEME_STORE_URL}/theme/${template.slug}/${template.id}`}
                                      href="#"
                                      target="_blank"
                                    >
                                      {template.name}
                                    </a>
                                  </h6>
                                  <p className="text-muted">
                                    by {template?.developer?.company_name}
                                  </p>
                                </div>
                                <div className="actions">
                                  {checkTheme(template) ? (
                                    <span className="text-success">
                                      <IoCheckmarkCircleOutline size={20} />{" "}
                                      Added
                                    </span>
                                  ) : (
                                    <>
                                      {template.price == 0 ? (
                                        <button
                                          className="btn btn-sm btn-light"
                                          onClick={() => addTemplate(template)}
                                        >
                                          {adding === template.id ? (
                                            <div
                                              className="spinner-border spinner-border-sm text-white"
                                              role="status"
                                            >
                                              <span className="visually-hidden">
                                                Loading...
                                              </span>
                                            </div>
                                          ) : (
                                            "Add"
                                          )}
                                        </button>
                                      ) : (
                                        <a href="" className="text-bold">
                                          {getSymbolFromCurrency("NGN")}
                                          {template.price}
                                        </a>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                      </div>
                      <div className="loader-section">
                        {storeTemplates.state === "loading" && (
                          <div className="py-5">
                            <Loader
                              message={<p className="text-muted">Loading...</p>}
                            />
                          </div>
                        )}
                      </div>
                    </ModalBox>
                  </div>
                </div>
                <div className="card-body">
                  {themes.loading ? (
                    <div className="py-5">
                      <Loader
                        message={<p className="text-muted">Loading...</p>}
                      />
                    </div>
                  ) : (
                    <Fragment>
                      {themes.themes.length > 0 && (
                        <Fragment>
                          <Row>
                            {themes.themes.map((theme, index) => (
                              <Col
                                md={4}
                                key={index}
                                style={{ marginBottom: 10 }}
                              >
                                <div className="position-relative theme-card">
                                  <img src={theme.image} width={"100%"} />
                                  <div className="position-absolute p-5 top-0 start-0 end-0 bottom-0 theme-overlay text-center">
                                    {theme.active != 1 && (
                                      <IconWithTooltip message={"Publish"}>
                                        <button
                                          className="btn btn-icon btn-light rounded-circle me-2"
                                          onClick={() => activateTheme(theme)}
                                        >
                                          <HiOutlineCheck />
                                        </button>
                                      </IconWithTooltip>
                                    )}
                                    <IconWithTooltip message={"Preview"}>
                                      <a
                                        href={`https://${store.slug}.${
                                          process.env
                                            .REACT_APP_STOREFRONT_DOMAIN
                                        }?theme_id=${theme.id}${
                                          store.store_type === "development"
                                            ? `&key=${storeFrontToken}`
                                            : ""
                                        }`}
                                        target="_blank"
                                        className="btn btn-icon btn-light rounded-circle"
                                      >
                                        <HiOutlineEye />
                                      </a>
                                    </IconWithTooltip>
                                    {/* <IconWithTooltip message={'Customize'}>
                                      <a
                                        href={`${process.env.REACT_APP_EDITOR_URL}/theme/${theme.slug}?version=${theme.version}`}
                                        target="_blank"
                                        className="btn btn-icon btn-light rounded-circle ms-2"
                                      >
                                        <RiPaletteLine />
                                      </a>
                                    </IconWithTooltip> */}
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <h6 className="mb-1">{theme.name} </h6>
                                  <VersionSelect theme={theme} />
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>

            {/* Banners */}
            <Banners />

            {/* Colors */}
            <Colors />
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-9">
              <div className="row">
                <div className="col-xxl-6">
                  <div className="card card-height-100">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Google Analytics
                      </h4>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <label
                            htmlFor="default-modal"
                            className="form-label text-muted"
                          >
                            Activate
                          </label>
                          <input
                            className="form-check-input code-switcher"
                            type="checkbox"
                            checked={settings.google_analytics}
                            onChange={() =>
                              updateSettings(
                                "google_analytics",
                                !settings.google_analytics
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <p className="text-muted">
                          Add your google analytics script from Google.
                        </p>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => openModal("google_analytics_script")}
                          >
                            <i className="ri-pencil-fill fs-16"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6">
                  <div className="card card-height-100">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Facebook Pixels
                      </h4>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <label
                            htmlFor="default-modal"
                            className="form-label text-muted"
                          >
                            Activate
                          </label>
                          <input
                            className="form-check-input code-switcher"
                            type="checkbox"
                            checked={settings.facebook_pixels}
                            onChange={() =>
                              updateSettings(
                                "facebook_pixels",
                                !settings.facebook_pixels
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <p className="text-muted">
                          Add your facebook pixels script from Meta.
                        </p>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => openModal("facebook_pixels_script")}
                          >
                            <i className="ri-pencil-fill fs-16"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6">
                  <div className="card card-height-100">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">Password</h4>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <label
                            htmlFor="default-modal"
                            className="form-label text-muted"
                          >
                            Activate
                          </label>
                          <input
                            className="form-check-input code-switcher"
                            type="checkbox"
                            checked={settings.store_is_password_protected}
                            onChange={() =>
                              updateSettings(
                                "store_is_password_protected",
                                !settings.store_is_password_protected
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex gap-2 justify-content-between">
                        <p className="text-muted"></p>
                        <div className="input-group mb-3">
                          <input
                            type={visibility ? "text" : "password"}
                            value={password}
                            name="password"
                            onChange={handlePasswordChange}
                            className="form-control"
                            id="Password"
                            placeholder="Enter your password"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              {visibility ? (
                                <AiOutlineEye
                                  className="tj-icon1"
                                  onClick={() => setVisibility(false)}
                                />
                              ) : (
                                <AiOutlineEyeInvisible
                                  className="tj-icon1"
                                  onClick={() => setVisibility(true)}
                                />
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => savePassword(password)}
                          >
                            {/* <i className="ri-pencil-fill fs-16"></i> */}
                            {updating ? (
                              <div
                                className="spinner-border spinner-border-sm text-white"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="live-preview">
          <div>
            <ModalBox
              show={
                modal === "google_analytics_script" ||
                  modal === "facebook_pixels_script"
                  ? true
                  : false
              }
              handleClose={closeModal}
              title={
                modal === "google_analytics_script"
                  ? "Google Analytics Script"
                  : "Facebook Pixels Script"
              }
              closeBtn={
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={closeModal}
                >
                  {" "}
                  Close
                </button>
              }
              saveBtn={
                <button
                  type="button"
                  disabled={saving}
                  className={`btn btn-primary ${saving ? "disabled" : ""}`}
                  onClick={() => saveIntegration()}
                >
                  {saving ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-white"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>{" "}
                      Saving...
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              }
            >
              <form
                action=""
                method="POST"
                className="form"
                id="integrationForm"
                encType="multipart/form-data"
              >
                <div className="card-body">
                  <div className="form-group row mb-3">
                    <div className="col-lg-12 col-xl-12">
                      <textarea
                        className="form-control form-control-solid"
                        rows="5"
                        name="description"
                        placeholder="Paste your script here..."
                        value={
                          modal === "google_analytics_script"
                            ? settings?.google_analytics_script
                            : settings?.facebook_pixels_script
                        }
                        onChange={(e) => {
                          handleFieldChange(e);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </ModalBox>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Storefront;
