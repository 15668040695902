import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import AnnouncementsSetup from "./Pages/Settings/AnnouncementsSetup";
import AppLayout from "./Shared/Layouts/AppLayout";
import { AuthContext } from "./Contexts/AuthContext";
import BillingAndSubscription from "./Pages/Settings/BillingAndSubscription";
import Campaigns from "./Pages/Campaigns/Campaigns";
import Collections from "./Pages/Collections/Collections";
import Coupons from "./Pages/Coupons/Coupons";
import Customers from "./Pages/Customers/Customers";
import Dashboard from "./Pages/Dashboard";
import DeliverySetup from "./Pages/Settings/DeliverySetup";
import DomainIntegration from "./Pages/Settings/DomainIntegration";
import EmailBuilder from "./Pages/Campaigns/EmailBuilder";
import Error404 from "./Pages/Errors/404";
import Expenses from "./Pages/Expenses/Expenses";
import Invoice from "./Pages/Invoice/Invoice";
import InvoiceForm from "./Pages/Invoice/InvoiceForm";
import Invoices from "./Pages/Invoice/Invoices";
import OrderDetail from "./Pages/Orders/OrderDetail";
import Orders from "./Pages/Orders/Orders";
import PaymentLinks from "./Pages/Payments/PaymentLinks";
import PaymentSetup from "./Pages/Settings/PaymentSetup";
import ProductDetails from "./Pages/Products/ProductDetails";
import ProductUpload from "./Pages/Products/ProductUpload";
import Products from "./Pages/Products/Products";
import Profile from "./Pages/Profile";
import Provider from "./Pages/Providers";
import Referrals from "./Pages/Referrals/Referrals";
import SalesChannels from "./Pages/Settings/SalesChannels";
import StaffAccount from "./Pages/Settings/StaffAccount";
import StoreSetup from "./Pages/Settings/StoreSetup";
import Storefront from "./Pages/Settings/Storefront";
import Subscribe from "./Pages/Settings/Subscribe";
import Subscribers from "./Pages/Subscribers/Subscribers";
import SubscriptionStatus from "./Pages/Settings/SubscriptionStatus";
import Transactions from "./Pages/Payments/Transactions";
import Withdrawals from "./Pages/Payments/Withdrawals";
import Sales from "./Pages/Analytics/Sales";
import CampaignFormPage from "./Pages/Campaigns/CampaignForm";
import UsersWallet from "./Pages/UsersWallet/UsersWallet";
import Navigations from "./Pages/Settings/Navigation";
import Pages from "./Pages/Settings/Pages";
import AddMenu from "./Pages/Settings/AddMenu";
import AddPage from "./Pages/Settings/AddPage";
import Blog from "./Pages/Settings/Blog";
import AddBlog from "./Pages/Settings/AddBlog";
import BlogDetails from "./Pages/Settings/BlogDetails";
const App = () => {
  const { user } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="/analytics" element={<Sales />} />
        <Route path="products" element={<Products />} />
        <Route path="products/create" element={<ProductUpload mode="new" />} />
        <Route
          path="product/:product_id/edit"
          element={<ProductUpload mode="edit" />}
        />
        <Route
          path="product/:product_id/details"
          element={<ProductDetails />}
        />

        <Route path="collections" element={<Collections />} />
        <Route path="coupons" element={<Coupons />} />

        <Route path="sales-and-orders" element={<Orders />} />
        <Route path="referrals" element={<Referrals />} />
        <Route path="order/:order_id" element={<OrderDetail update={true} />} />
        <Route
          path="order/:order_id/details"
          element={<OrderDetail update={false} />}
        />

        <Route path="customers" element={<Customers />} />
        <Route path="customers/:selected" element={<Customers />} />

        <Route path="invoices" element={<Invoices />} />
        <Route path="invoice/:invoice_id" element={<Invoice />} />
        <Route path="invoices/create" element={<InvoiceForm />} />
        <Route path="invoices/create/:order_id" element={<InvoiceForm />} />
        <Route path="invoice/:invoice_id/edit" element={<InvoiceForm />} />

        <Route path="payments/transactions" element={<Transactions />} />
        <Route
          path="payments/transactions/:selected"
          element={<Transactions />}
        />
        <Route path="payments/links" element={<PaymentLinks />} />

        <Route path={"/expenses"} element={<Expenses />} />

        <Route path="settings/store-setup" element={<StoreSetup />} />
        <Route path="settings/delivery" element={<DeliverySetup />} />
        <Route path="settings/profile" element={<Profile />} />
        <Route
          path="settings/domain-integration"
          element={<DomainIntegration />}
        />
        <Route path="settings/sales-channels" element={<SalesChannels />} />

        <Route path="settings/online-store/themes" element={<Storefront />} />

        <Route exact path="/settings/online-store/navigations">
          <Route index={true} element={<Navigations />} />
          <Route path="addMenu" element={<AddMenu />} />
          <Route path=":id" element={<AddMenu />} />
        </Route>
        <Route exact path="/settings/online-store/pages">
          <Route index={true} element={<Pages />} />
          <Route path="addPage" element={<AddPage/>} />
          <Route path=":id" element={<AddPage />} />
        </Route>
        <Route path="/blogs" element={<Blog />} />
        <Route path="blogs/create" element={<AddBlog mode="new" />} />
        <Route
          path="blogs/:blog_id/edit"
          element={<AddBlog mode="edit"/>}
        />
        <Route
          path="blogs/:blog_id/details"
          element={<BlogDetails />}
        />
        <Route path="marketing/campaigns" element={<Campaigns />} />
        <Route
          path="marketing/campaigns/create"
          element={<CampaignFormPage mode="new" />}
        />
        <Route
          path="marketing/campaign/:campaign_id/edit"
          element={<CampaignFormPage mode="edit" />}
        />

        <Route path="marketing/campaign/editor" element={<EmailBuilder />} />

        <Route
          path="/marketing/announcements"
          element={<AnnouncementsSetup />}
        />

        <Route path="marketing/subscribers" element={<Subscribers />} />

        <Route path="provider/:provider" element={<Provider />} />

        {user.is_vendor === 1 && (
          <>
            <Route path="settings/staffs" element={<StaffAccount />} />
            <Route path="payments/wallets" element={<UsersWallet />} />
            <Route path="payments/withdrawals" element={<Withdrawals />} />
            <Route path="settings/payment-setup" element={<PaymentSetup />} />
            <Route
              path="settings/billing-and-subscription"
              element={<BillingAndSubscription />}
            />
            <Route path="subscribe/:group/plans" element={<Subscribe />} />
            <Route
              path="subscription/:status"
              element={<SubscriptionStatus />}
            />
          </>
        )}
      </Route>

      <Route
        path="dashboard/access/choose/plan"
        element={<BillingAndSubscription expired={true} />}
      />
      <Route
        path="dashboard/access/subscribe/:group/plans"
        element={<Subscribe expired={true} />}
      />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default App;
