import axios from 'axios';
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap'
import BlankPage from "../../Components/BlankPage";
import { AuthContext } from '../../Contexts/AuthContext';
import { errorResponse, responseMessage } from '../../libs/app';
import { Link } from 'react-router-dom';
import ModalBox from '../../Components/ModalBox';
import { AppContext } from '../../Contexts/AppContext';
import SimpleBar from 'simplebar-react';
import Spinner from 'react-bootstrap/Spinner';
import IconWithTooltip from '../../Components/IconWithTooltip'

export const checkIfChannelConnected = (slug, connectedChannels) => {
    return connectedChannels.find((ch) => slug === ch.sales_channel_slug);
}

const ConfigurationModal = ({ channel, syncChannelSettings, closeModal }) => {

    const [saving, setSaving] = useState(false);
    const [settings, setSettings] = useState({});
    const { countries } = useContext(AppContext)

    useEffect(() => {
        channel && setSettings(channel.settings)
    }, [channel])

    const handleChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value });
    }

    const saveChannelsettings = async (channel) => {
        try {
            setSaving(true);
            const response = await axios.post('/settings/connected-sales-channels', { ...channel, settings });
            if (response.data.success) {
                responseMessage(response.data.message, "success");
                closeModal()

                await syncChannelSettings(channel)
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        } finally {
            setSaving(false);
        }
    }

    const GoogleSettings = ({ settings, setSettings, handleChange }) => {

        const handleCountryCheck = (e, country) => {
            var selectedZones = settings?.selectedZones || [];
            if (e.target.checked === true) {
                selectedZones.push(country.short_code)
                return setSettings({ ...settings, selectedZones });
            }

            selectedZones = selectedZones.filter((zone) => zone !== country.short_code);
            return setSettings({ ...settings, selectedZones });
        }

        const checkCountry = (country) => {
            if (settings?.selectedZones === undefined) {
                return false;
            }

            if (settings.selectedZones.includes(country.short_code)) {
                return true;
            }

            return false;
        }

        // const setForAllZones = (status) => {
        //     status = (status) ? false : true;
        //     setSettings({ ...settings, forAllZones: status })
        // }

        return <Fragment>
            <form>
                <div className="form-group mb-3">
                    <label className="form-label text-right"><b>Verification Code</b></label>
                    <textarea className="form-control form-control-solid" name="verification" type="text"
                        placeholder="Enter google verification code" onChange={(e) => handleChange(e)}>{settings?.verification}</textarea>
                </div>
                {/* <div className="form-group mb-3">
                    <div className="col-lg-12 col-xl-12">
                        <div className="form-check form-switch form-switch-left form-switch-md">
                            <label htmlFor="default-modal" className="form-label">Include All Zones</label>
                            <input className="form-check-input code-switcher" type="checkbox" checked={settings?.forAllZones}
                                onChange={(e) => setForAllZones(settings?.forAllZones)}
                            />
                        </div>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <b>Note:</b> Including all zones might require additional settings on your Google Merchant Center such as shipping and taxes to display your products in some regions.
                        </div>
                    </div>
                </div> */}
                {!settings?.forAllZones &&
                    <div className="form-group mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Select Target Countries</label>
                        <SimpleBar style={{ maxHeight: 320 }} className='border px-2 rounded-3'>
                            {countries.length > 0 &&
                                <ul className="navbar-nav" id="navbar-nav">
                                    {countries.map((country) =>
                                        <li className="nav-item mb-1" key={`country-${country.id}`}>
                                            <div
                                                className="nav-link menu-link p-0"
                                            >
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`country-${country.id}`}
                                                        name={`checkAllCountry-${country.id}`}
                                                        checked={checkCountry(country)}
                                                        onChange={(e) => handleCountryCheck(e, country)}
                                                    />
                                                    <label htmlFor={`country-${country.id}`}>{country.name}</label>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            }
                        </SimpleBar>
                    </div>
                }
            </form>
        </Fragment>
    }

    return <ModalBox
        show={channel !== null}
        handleClose={closeModal}
        title={"Channel Settings"}
        closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
        saveBtn={
            (<button
                type="button"
                disabled={saving}
                className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                onClick={() => saveChannelsettings(channel)}>
                {saving
                    ? <>
                        <div className="spinner-border spinner-border-sm text-white" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> Saving...
                    </> : 'Save'}
            </button>)}
    >
        {channel?.sales_channel_slug === "google" && <GoogleSettings settings={settings} setSettings={setSettings} handleChange={handleChange} />}
    </ModalBox>
}

const SalesChannels = () => {

    document.title = 'Sales Channels | Taojaa - Store Manager App';

    const { store, subscription, handleUpgradeModalShow } = useContext(AuthContext);
    const [connectedChannels, setConnectedChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [syncing, setSyncing] = useState(null);

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }

        getVendorConnectedChannels();
    }, [store, subscription]);


    async function getVendorConnectedChannels() {
        try {
            const response = await axios.get('/settings/connected-sales-channels');
            if (response.data.success) {
                setConnectedChannels(response.data.data)
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    const syncChannelSettings = async (channel) => {

        if (channel.sales_channel_slug === "google" && subscription.plan.group_name === "Starter") {
            return handleUpgradeModalShow(
                'Upgrade to Professional',
                'Your access has been downgraded due to failure to renew your plan, upgrade/renew plan to continue enjoying unlinted access'
            )
        }

        if ((channel.sales_channel_slug === "meta" || channel.sales_channel_slug === "pinterest")
            && (subscription.plan.group_name === "Starter" || subscription.plan.group_name === "Professional")) {
            return handleUpgradeModalShow(
                'Upgrade to Premium',
                'Your current plan does not have access to Pinterest and Meta channels, upgrade/renew plan to continue enjoying unlinted access'
            )
        }

        try {
            setSyncing(channel.sales_channel_slug);
            const response = await axios.post(`${process.env.REACT_APP_MARKETING_SERVICE_URL}/${channel.sales_channel_slug}/datafeed`);
            if (response.data.status === "success") {
                responseMessage("Sync completed successfully!", "success")
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        } finally {
            setSyncing(null);
        }
    }

    const channels = [
        {
            id: 1,
            name: "Google",
            slug: "google",
            logo: "https://res.cloudinary.com/dp7quxpsq/image/upload/v1704205875/zqn1dtmvqlilci0ztskt.png",
            description: "Sell to millions of users using Google shopping search, connect your Google merchant account and start selling on Google."
        },
        {
            id: 2,
            name: "Meta (Facebook & Instagram)",
            slug: "meta",
            logo: "https://res.cloudinary.com/dp7quxpsq/image/upload/v1704208156/zovblm00rfziesbhsvjs.png",
            description: "Connect your inventory to your Facebook and Instagram account and start selling to millions of users with Facebook Ads."
        },
        {
            id: 3,
            name: "Pinterest",
            slug: "pinterest",
            logo: "https://res.cloudinary.com/dp7quxpsq/image/upload/v1704208290/h2ioctcy0mtnvoyfzv6w.png",
            description: "Connect with shoppers on Pinterest, manage your inventory, start sharing pins and showcase your products to users on Pinterest."
        },
        {
            id: 4,
            name: "TikTok Commerce",
            slug: "tiktok-commerce",
            logo: "https://res.cloudinary.com/dp7quxpsq/image/upload/v1704223243/kskor3dcqzzjoyjckalu.png",
            description: "Millions of users use TikTok on daily basis, with TikTok Commerce you can increase your reach and make more sales with just a click away.",
            comingSoon: true
        }
    ];

    const configureChannel = (channel) => {
        setSelectedChannel(channel);
    }

    const connectChannel = (channel) => {

        if (channel.slug === "google") {
            if (subscription.plan.group_name === "Free") {
                return handleUpgradeModalShow(
                    'Upgrade Plan',
                    'Upgrade your plan to connect with your online store with Google, Facebook, Instagram and Pinterest'
                )
            }
        }

        if (channel.slug === "meta" || channel.slug === "pinterest") {
            if (subscription.plan.group_name === "Starter") {
                return handleUpgradeModalShow(
                    'Upgrade to Professional Plan',
                    'Upgrade to professional plan to connect with Facebook, Instagram and Pinterest'
                )
            }
        }

        window.open(`${process.env.REACT_APP_MARKETING_SERVICE_URL}/${channel.slug}/initiate/auth?store_id=${store.store_id}`);
    }


    const checkIfConnected = (channel) => {
        return checkIfChannelConnected(channel.slug, connectedChannels);
    }

    return <BlankPage title="Sales Channels" titleColumSize={12}>
        <div className="col-xxl-9">
            <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Connect Sales Channels</h4>
                    <div className="flex-shrink-0">

                    </div>
                </div>
                <div className="card-body">
                    <div className='d-flex justify-content-between'>
                        <div className='shipping-options'>
                            <p className="text-muted">Start selling to millions of buyers across different channels today.</p>
                        </div>
                    </div>
                    <div className='mt-3 px-3'>
                        {channels.map((channel) =>
                            <Col lg={12} key={`channel-${channel.id}`} className={`mb-3 border rounded-2 p-3 provider-card`}>
                                <Row className='align-items-center'>
                                    <Col lg={1} xs={3}>
                                        <div className='provider-logo text-center'>
                                            <img src={channel.logo} width={'100%'} alt={`${channel.name}`} />
                                        </div>
                                    </Col>
                                    <Col lg={8} xs={8}>
                                        <div className='provider-details mb-3'>
                                            <h5>{channel.name}</h5>
                                            <small>{channel.description}</small>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12} className='text-end'>
                                        {channel?.comingSoon ?
                                            <span className="badge badge-soft-danger text-uppercase">Coming Soon</span>
                                            : <Fragment>
                                                {checkIfConnected(channel) ?
                                                    <Fragment>
                                                        {syncing === channel.slug ?
                                                            <>
                                                                <Spinner animation="border" variant="success" size="sm" /> <span className='text-succss'>Syncing...</span>
                                                            </>
                                                            : <>
                                                                {/* <span className="badge badge-soft-success text-uppercase">Connected</span> */}
                                                                <IconWithTooltip message={"Channel Settings"}>
                                                                    <button className="btn btn-rounded btn-light btn-icon ms-2"
                                                                        disabled={channel.slug !== "google"}
                                                                        onClick={() => configureChannel(checkIfConnected(channel))}>
                                                                        <i className='ri-settings-2-line' style={{ fontSize: 20 }}></i>
                                                                    </button>
                                                                </IconWithTooltip>
                                                                <IconWithTooltip message={"Sync"}>
                                                                    <button className="btn btn-rounded btn-light btn-icon ms-2"
                                                                        onClick={() => syncChannelSettings(checkIfConnected(channel))}>
                                                                        <i className=' ri-refresh-line' style={{ fontSize: 20 }}></i>
                                                                    </button>
                                                                </IconWithTooltip>
                                                            </>}
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        {subscription.plan.group_name === "Starter" || subscription.plan.group_name === "Free" && (channel.slug === "meta" || channel.slug === "pinterest")
                                                            ? <Link to={"/settings/billing-and-subscription"} className="btn btn-sm btn-success">Upgrade to Pro</Link>
                                                            :
                                                            <>{subscription.plan.group_name === "Free" && channel.slug === "google" ?
                                                                <Link to={"/settings/billing-and-subscription"} className="btn btn-sm btn-success">Upgrade to Starter</Link>
                                                                : <button className="btn btn-sm btn-primary" onClick={() => connectChannel(channel)}>Connect Channel</button>}</>
                                                        }
                                                    </Fragment>
                                                }
                                            </Fragment>}
                                    </Col>
                                </Row>
                            </Col>)}
                    </div>
                </div>
            </div>
        </div>
        <ConfigurationModal channel={selectedChannel} syncChannelSettings={syncChannelSettings} closeModal={() => setSelectedChannel(null)} />
    </BlankPage>
}

export default SalesChannels;