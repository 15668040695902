import React, { Fragment, useContext, useEffect, useState } from 'react';
import ModalBox from '../../../../Components/ModalBox';
import { Link } from 'react-router-dom';
import ImagePreview from '../../../../Components/ImagePreview';
import MyDropzone from '../../../../Components/MyDropZone';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import $ from "jquery";
import DeleteModal from '../../../../Components/DeleteModal';
import { errorResponse, responseMessage } from '../../../../libs/app';
import axios from 'axios';
import { AuthContext } from '../../../../Contexts/AuthContext';


const BannerSlide = ({ banners, perView = 1, spaceBetween = 0, style, editBanner, setForDelete }) => {

    return (
        <Swiper
            modules={[Navigation, Scrollbar, A11y]}
            className={style}
            navigation
            spaceBetween={spaceBetween}
            slidesPerView={perView}>
            {
                banners.map((banner, index) =>
                    <SwiperSlide className="swiper-slide" key={`slide-${index}`}>
                        <div className='row'>
                            <div className='col-md-4'>
                                <img style={{ width: "100%", height: "150px" }} alt='Banner' src={`${banner.image}`} />
                            </div>
                            <div className='col-md-8'>
                                <h3>{banner.heading}</h3>
                                <p>{banner.sub_heading}</p>
                                <div className='d-flex gap-3'>
                                    <p><b>Button Text:</b> {banner.btn_text}</p>
                                    <p><b>Button Link:</b> <a href={banner.link} className='text-primary' target='_blank'>{banner.link}</a></p>
                                </div>
                                <div className='actions'>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                        <li
                                            className="list-inline-item edit"
                                            title="Edit"
                                        >
                                            <Link
                                                to="#"
                                                className="text-primary d-inline-block edit-item-btn"
                                                onClick={() => editBanner(banner)}
                                            >
                                                <i className="ri-pencil-fill fs-16"></i> Edit
                                            </Link>
                                        </li>
                                        <li
                                            className="list-inline-item"
                                            title="Remove"
                                        >
                                            <Link
                                                className="text-danger d-inline-block"
                                                to="#deleteBanner"
                                                onClick={() =>
                                                    setForDelete(banner.id)
                                                }
                                            >
                                                <i className="ri-delete-bin-5-fill fs-16"></i> Delete
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                )
            }
        </Swiper>
    )
}

const Banners = () => {

    const { store } = useContext(AuthContext);
    const [saving, setSaving] = useState(false);
    const [banner, setBanner] = useState({
        heading: null,
        sub_heading: null,
        image: null,
        btn_text: null,
        link: null
    });
    const [banners, setBanners] = useState([]);
    const [bannerModal, setBannerModal] = useState(false);
    const [forDelete, setForDelete] = useState(null);

    useEffect(() => {
        getBanners();
    }, []);

    async function getBanners() {

        try {

            const response = await axios.get(`/settings/storefront/banners`);
            if (response.data.success) {
                setBanners(response.data.banners)
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function saveBanner() {

        try {

            const response = await axios.post(`/settings/storefront/save-banner`, banner);
            if (response.data.success) {
                localStorage.setItem("temporary_banner_image", null);
                closeBannerModal();
                getBanners();
            }

            responseMessage(response.data.message, "success");

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function deleteBanner() {

        try {

            const response = await axios.delete(`/settings/storefront/delete-banner/${forDelete}`);
            if (response.data.success) {
                getBanners();
                closeDeleteModal();
            }

            responseMessage(response.data.message, "success");

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    const bannerUploadResponse = (response) => {
        if (response.success) {
            setBanner({ ...banner, image: response.image })
            localStorage.setItem("temporary_banner_image", response.image);

            $("#uploadStatus").html(
                '<p style="color:#28A74B;">File uploaded successfully!</p>'
            );

            responseMessage(response.message, "success");
        }
        else if (response.success == false) {

            $("#uploadStatus").html(
                `<p style="color:#EA4335;">${response.message}</p>`
            );
        }

        $(".progress").addClass("hide");
        $(".progress-bar").width("0%");
    }

    const onRemove = () => {
        setBanner({ ...banner, image: null });
        localStorage.setItem("temporary_banner_image", null);
    }

    const handleBannerChange = (evt) => {
        const { name, value } = evt.target;
        setBanner({ ...banner, [name]: value });
    }

    const editBanner = (banner) => {
        setBanner(banner);
        openBannerModal();
    }

    const openBannerModal = () => {
        setBannerModal(true)
    }
    const closeBannerModal = () => {
        setBannerModal(false)
        setBanner({
            heading: null,
            sub_heading: null,
            image: null,
            btn_text: null,
            link: null
        })
    }

    const closeDeleteModal = () => {
        setForDelete(null);
    }

    return (
        <Fragment>
            <div className="col-xxl-9">
                <div className="card card-height-100">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Banners</h4>
                    </div>
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <p className="text-muted text-muted">Banners will be displayed on your storefront.</p>
                            <div className=''>
                                <button type="button" className="btn btn-primary btn-sm" onClick={openBannerModal}>
                                    <i className="ri-upload-cloud-2-line"></i> <span className='desktop'>Add Banner</span>
                                </button>
                            </div>
                        </div>
                        <div className='mt-2'>
                            {banners.length > 0 && (
                                <BannerSlide banners={banners} editBanner={editBanner} setForDelete={setForDelete} />
                            )}

                        </div>
                        <div className="live-preview">
                            <div>
                                <ModalBox
                                    show={bannerModal}
                                    handleClose={closeBannerModal}
                                    title={'Upload Banner'}
                                    closeBtn={<button type="button" className="btn btn-light" onClick={closeBannerModal}> Close</button>}
                                    saveBtn={
                                        (<button
                                            type="submit"
                                            disabled={saving}
                                            className={`btn btn-success ${saving ? 'disabled' : ''}`}
                                            onClick={saveBanner}>
                                            {saving
                                                ? <>
                                                    <div className="spinner-border spinner-border-sm text-white" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div> Saving...
                                                </> : 'Save Banner'}
                                        </button>)}
                                >
                                    <div className='mb-3'>
                                        <div className='col-md-12'>
                                            {banner.image ? (
                                                <ImagePreview
                                                    image={banner.image}
                                                    query={banner?.id ? { 'banner': banner.id } : null}
                                                    onRemove={onRemove}
                                                />
                                            ) : (
                                                <MyDropzone
                                                    endpoint={`/settings/storefront/upload-banner`}
                                                    message="Select image"
                                                    max_files={1}
                                                    beforeUpload={() => {
                                                        $("#uploadStatus").html("");

                                                        $(".progress").removeClass("hide");
                                                        $(".progress-bar").width("0%");
                                                    }}
                                                    onUploadResponse={bannerUploadResponse}
                                                    onUploadError={() => {
                                                        $("#uploadStatus").html(
                                                            '<p style="color:#EA4335;">File upload failed, please try again.</p>'
                                                        );

                                                        $(".progress").addClass("hide");
                                                        $(".progress-bar").width("0%");
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="progress mt-2 hide">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: 0 }}
                                                aria-valuenow="0"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                                0%
                                            </div>
                                        </div>
                                        <div id="uploadStatus" className="mt-2 text-center"></div>
                                    </div>

                                    <div className="mb-3">
                                        <label
                                            htmlFor="banner-heading"
                                            className="form-label"
                                        >
                                            Heading
                                        </label>
                                        <input
                                            type="text"
                                            id="banner-heading"
                                            className="form-control"
                                            name="heading"
                                            placeholder={"Welcome to " + store.name}
                                            value={banner.heading}
                                            onChange={handleBannerChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label
                                            htmlFor="banner-sub"
                                            className="form-label"
                                        >
                                            Sub Heading
                                        </label>
                                        <input
                                            type="text"
                                            id="banner-sub"
                                            placeholder={"We Offer Affordable " + store.category.name + " Products & Services"}
                                            className="form-control"
                                            name="sub_heading"
                                            value={banner.sub_heading}
                                            onChange={handleBannerChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label
                                            htmlFor="banner-btn_text"
                                            className="form-label"
                                        >
                                            Button Text
                                        </label>
                                        <input
                                            type="text"
                                            id="banner-btn_text"
                                            className="form-control"
                                            name="btn_text"
                                            placeholder='Shop Now'
                                            value={banner.btn_text}
                                            onChange={handleBannerChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label
                                            htmlFor="banner-link"
                                            className="form-label"
                                        >
                                            Link
                                        </label>
                                        <input
                                            type="text"
                                            id="banner-link"
                                            className="form-control"
                                            name="link"
                                            placeholder={"https://" + store.slug + ".com/shop"}
                                            value={banner.link}
                                            onChange={handleBannerChange}
                                        />
                                    </div>

                                </ModalBox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal
                show={forDelete ? true : false}
                title={'Delete confirmation'}
                message={'Are you sure you want to delete this banner?'}
                handleDelete={deleteBanner}
                handleClose={closeDeleteModal}
            />
        </Fragment>
    );
};

Banners.propTypes = {

};

export default Banners;